import { Submission } from "src/redux/types/exam-submission";
import { DashboardDefaultContainer } from "../dashboard-default-container";
import { useAppDispatch } from "src/redux/hooks";
import { useState } from "react";
import ExaminerPaperListView from "./ExaminerPaperListView";
import ExaminerPaperMarksView from "./ExaminerPaperMarksView";
import { setCurrentExaminerPaper } from "src/redux/reducers";


type View = "list" | "marks"

const ExaminerDashboard = () => {
    const [view, setView] = useState<View>("list");

    const dispatch = useAppDispatch();

    const onClickCheckPaper = (submission: Submission) => {
      dispatch(setCurrentExaminerPaper(submission));
      setView("marks");
    };

    const setDefaultView = () => {
      setView("list");
      window.location.reload();
    };

    const renderView = () => {
      switch (view) {
        case "list":
          return <ExaminerPaperListView onClickCheck={onClickCheckPaper} />;
        case "marks":
          return <ExaminerPaperMarksView defaultView={setDefaultView} />;
        default: 
          break;
      }
    };

    return (
      <DashboardDefaultContainer>{renderView()}</DashboardDefaultContainer>
    );
}

export default ExaminerDashboard