import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "src/components/buttons/shadcn-button";
import { Form, FormControl, FormField, FormItem, FormLabel } from "src/components/form/form";
import { Input } from "src/components/input/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/input/Select";
import { editQualifyingMarks } from "src/redux/api/_exams.api";
import { states } from "src/schemas/create-candidate";
import { Posts } from "src/utils/posts";
import { z } from "zod";

export const AddQualifyingMarksSchema = z.object({
  post: z.enum(Posts),
  state: z.enum(states as [string, ...string[]]),
  qualifyingMarks: z.string(),
});

export const AddQualifyingMarksForm: React.FC = () => {
  const form = useForm<z.infer<typeof AddQualifyingMarksSchema>>({
    resolver: zodResolver(AddQualifyingMarksSchema),
  });

  const [isPending, setIsPending] = useState<boolean>(false);

  const handleQualifyingMarks = async (values: z.infer< typeof AddQualifyingMarksSchema>) => {
    setIsPending(true);
    try {
      const response = await editQualifyingMarks(values);

      if(response) {
        console.log(response);
        toast.success("Qualifying Marks added Successfully!");
        setIsPending(false);
      }

    } catch (error) {
      console.error(error);
      toast.error("Failed to save Marks!")
      setIsPending(false);
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleQualifyingMarks)}
        className="flex flex-col gap-y-2"
      >
        <FormField
          name="post"
          defaultValue={undefined}
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Post</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select post" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {Posts.map((post) => {
                    return (
                      <SelectItem key={post} value={post}>
                        {post}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          name="state"
          defaultValue={undefined}
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select State" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {states.map((state) => {
                    return (
                      <SelectItem key={state} value={state}>
                        {state}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="qualifyingMarks"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Qualifying Marks</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Qualifying Marks" type="text" />
              </FormControl>
            </FormItem>
          )}
        />
        <Button disabled={isPending}>
          {isPending ? <Loader2 className="animate-spin mx-auto" /> : "Submit"}
        </Button>
      </form>
    </Form>
  );
};
