import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { addQualifiedCandidate, getSubmissionByAdmit, saveSubmissionMarks } from 'src/redux/api/_exams.api';
import { UPLOADED_ANSWER_URL } from 'src/redux/api/appConst';
import { useAppSelector } from 'src/redux/hooks';
import { ExamSubmission } from 'src/redux/types/single-submission';
import ExaminerPaperMarksHeader from './ExaminerPaperMarksHeader';

interface ExaminerPaperMarksViewProps {
  defaultView: ()=> void;
}

const ExaminerPaperMarksView = ({
  defaultView,
}: ExaminerPaperMarksViewProps) => {
  const [currentPaper, setCurrentPaper] = useState<ExamSubmission[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const optionalQuestionSubmissions: ExamSubmission[] = [];
  const shortQuestionSubmissions: ExamSubmission[] = [];
  const longQuestionSubmissions: ExamSubmission[] = [];

  const { currentExaminerPaper } = useAppSelector((state) => state.userReducer);

  const fetchSubmissionByAdmit = async () => {
    setLoading(true);
    try {
      const response = await getSubmissionByAdmit(
        currentExaminerPaper.admitCardNumber
      );

      if (response?.message.toLowerCase() === "success") {
        setCurrentPaper(response.data.exam_submissions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const questionTypeSeparator = (paper: ExamSubmission[]) => {
    paper.forEach((currentPaper) => {
      const questionType = currentPaper.question.type.toLowerCase();

      switch (questionType) {
        case "optional":
          optionalQuestionSubmissions.push(currentPaper);
          break;
        case "short":
          shortQuestionSubmissions.push(currentPaper);
          break;
        case "desc":
          longQuestionSubmissions.push(currentPaper);
          break;
        default:
          break;
      }
    });
  };

  if (currentPaper) {
    questionTypeSeparator(currentPaper!);
  }

  useEffect(() => {
    fetchSubmissionByAdmit();
  }, []);

  const [marks, setMarks] = useState<{[examination_id: number]: number}>({});

  const markChangeHandler = (id: number, value: number) => {
    setMarks((prevMarks) => ({
      ...prevMarks,
      [id]: value,
    }));
  };

  const saveMarks = async (id: number) => {
    const obtainedMark = marks[id];

    const response = await saveSubmissionMarks(id, obtainedMark);

    if (response?.message.toLowerCase() === "success") {
      toast.success("Marks Saved!");
    } else {
      toast.error("Something went wrong while saving the Marks!");
    }
  };

  const calculateTotalMarksObtained = (): number => {
    if (!currentPaper) {
      return 0;
    }

    const updatedMarksArray = currentPaper.map((question) => {
      const { id, marks_obtained } = question.exam_submission;
      return marks[id] !== undefined ? marks[id] : marks_obtained;
    });

    return updatedMarksArray.reduce((acc, curr) => acc + curr, 0);
  };

  const totalMarksObtained = calculateTotalMarksObtained();

  const handleSavePaper = async () => {
    try {
      const paper = {
        admitcard_number: currentExaminerPaper.admitCardNumber,
        candidate_name: currentExaminerPaper.candidateName,
        examination_id: currentExaminerPaper.examinationId,
        marks : totalMarksObtained,
        post: currentExaminerPaper.postName,
        state: currentExaminerPaper.state,
      }

      const response = await addQualifiedCandidate(paper);

      if(response.message.toLowerCase()) {
        toast.success("Paper Saved Successfully!");
        defaultView();
      }

    } catch (error) {
      console.error(error);
      throw new Error("Failed to Save Paper!");
    }
  }

  const OptionalMarksTable = React.memo(() => {
    if (Array.isArray(optionalQuestionSubmissions)) {
      return (
        <tbody>
          {optionalQuestionSubmissions.map((paper, index) => {
            return (
              <tr key={index}>
                <td>
                  <span>{index + 1}</span>
                  <span> .{paper.question.question}</span>
                </td>
                <td>{paper.exam_submission.answer}</td>
                <td>{paper.question.answer}</td>
                <td>{paper.exam_submission.marks_obtained}</td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    return <div>Loading...</div>;
  });

  const ShortMarksTable = React.memo(() => {
    if (Array.isArray(shortQuestionSubmissions)) {
      return (
        <tbody>
          {shortQuestionSubmissions.map((paper, index) => {
            return (
              <tr key={index}>
                <td className="w-[29rem]">
                  <span>{index + 1}</span>.{" "}
                  <span>{paper.question.question}</span>
                </td>
                <td className="w-[22rem]">{paper.exam_submission.answer}</td>
                <td className="w-[29rem]">{paper.question.answer}</td>
                <td>
                  {!!paper.exam_submission.marks_obtained ? (
                    paper.exam_submission.marks_obtained
                  ) : (
                    <select
                      value={marks[paper.exam_submission.id] ?? ""}
                      onChange={(e) =>
                        markChangeHandler(
                          Number(paper.exam_submission.id),
                          Number(e.target.value)
                        )
                      }
                      className="w-fit px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="" disabled className='text-gray-400'>
                        Select Mark
                      </option>
                      {[0, 1, 2].map((mark) => (
                        <option key={mark} value={mark} className='text-gray-700'>
                          {mark}
                        </option>
                      ))}
                    </select>
                  )}
                </td>
                <td>
                  {!paper.exam_submission.marks_obtained && (
                    <button
                      onClick={() =>
                        saveMarks(Number(paper.exam_submission.id))
                      }
                      className="px-3 py-1 rounded-md bg-green-200 hover:border hover:border-green-500 border border-black"
                    >
                      SAVE
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    return null;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  const DisplayLongQuestions = () => {

    const [rotation, setRotation] = useState(0);

    const rotateImage = () => {
      setRotation(rotation + 90);
    };

    if (Array.isArray(longQuestionSubmissions)) {
      return (
        <div>
          {longQuestionSubmissions.map((paper, index) => {
            return (
              <div className="border-b border-slate-300 p-2 my-6 " key={index}>
                <h1 className="text-lg">
                  <span>{index + 1}</span>.{" "}
                  <span>{paper.question.question}</span>
                </h1>
                <div className="flex flex-col items-center mt-10">
                  {!!paper.exam_submission.answerUrl ? (
                    <img
                      src={`${UPLOADED_ANSWER_URL}/${paper.exam_submission.answerUrl}`}
                      alt="uploaded answer"
                      className="aspect-[3/4] m-auto max-w-[1080px] my-4 transition-transform duration-500"
                      style={{
                        transform: `rotate(${rotation}deg)`,
                      }}
                    />
                  ) : !!paper.exam_submission.answer ? (
                    <h1>{paper.exam_submission.answer}</h1>
                  ) : (
                    <h1 className="text-xl font-bold text-center">
                      Not Attempted.
                    </h1>
                  )}
                  {!!paper.exam_submission.answerUrl && (
                    <button
                      onClick={rotateImage}
                      className="mt-4 px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600 transition-colors"
                    >
                      Rotate Image
                    </button>
                  )}
                </div>

                <div>
                  {!!paper.exam_submission.marks_obtained ? (
                    <h1 className="text-xl font-bold text-end pr-4">
                      {" "}
                      Obtained Marks: {paper.exam_submission.marks_obtained}
                    </h1>
                  ) : (
                    <div className="flex gap-4 justify-end">
                      <select
                        value={marks[paper.exam_submission.id] ?? ""}
                        onChange={(e) =>
                          markChangeHandler(
                            Number(paper.exam_submission.id),
                            Number(e.target.value)
                          )
                        }
                        className="w-fit px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled className="text-gray-400">
                          Select Mark
                        </option>
                        {[0, 1, 2, 3, 4, 5].map((mark) => (
                          <option
                            key={mark}
                            value={mark}
                            className="text-gray-700"
                          >
                            {mark}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={() =>
                          saveMarks(Number(paper.exam_submission.id))
                        }
                        className="px-3 py-1 rounded-md bg-green-200 hover:border hover:border-green-500 border border-black"
                      >
                        {paper.exam_submission.id in marks ? "SAVED" : "SAVE"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return <div>Loading...</div>;
  };

  return (
    <>
      <ExaminerPaperMarksHeader defaultView={defaultView} />
      <div>
        <div className="flex gap-4 mb-8 pl-6">
          <div className="font-semibold text-xl">
            <h1>Candidate Name</h1>
            <h1>Admit Card Number</h1>
            <h1>Post Name</h1>
            <h1>State</h1>
            <h1>Exam Date</h1>
            <h1>Exam Start Time</h1>
          </div>
          <div className="font-semibold text-xl">
            <h1 className="uppercase">{currentExaminerPaper.candidateName}</h1>
            <h1>{currentExaminerPaper.admitCardNumber}</h1>
            <h1>{currentExaminerPaper.postName}</h1>
            <h1>{currentExaminerPaper.state}</h1>
            <h1>
              {moment(currentExaminerPaper.startTime).format("DD-MM-YYYY")}
            </h1>
            <h1>{moment(currentExaminerPaper.startTime).format("h:mm a")}</h1>
          </div>
          <div className="mt-12 ml-8 text-xl font-bold flex gap-6">
            <h1>
              Total Marks Obtained: <span>{totalMarksObtained}</span>
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">
            Optional Answers Marks
          </h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Correct Answer</th>
                <th>Marks Obtained</th>
              </tr>
            </thead>
            <OptionalMarksTable />
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">Short Answers Marks</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Expected Answer</th>
                <th>Marks Obtained</th>
                <th></th>
              </tr>
            </thead>
            <ShortMarksTable />
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">
            Descriptive Answers Marks
          </h1>
          <DisplayLongQuestions />
        </div>
        <div className="my-12 text-2xl font-bold flex justify-end gap-8">
          <h1>Total Marks Obtained: {totalMarksObtained}</h1>
          <button
            type="submit"
            className="px-3 py-1 text-lg rounded-md bg-green-200 hover:bg-green-500 hover:border hover:border-green-500 border border-black"
            onClick={handleSavePaper}
          >
            SAVE PAPER
          </button>
        </div>
      </div>
    </>
  );
};

export default ExaminerPaperMarksView