import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminUserModel,
  LeadModel,
  Project,
  ProjectDetails,
  UserModel,
} from "../types";
import { InsuranceCompany } from "../types/InsuranceCompany";
import { Material } from "../types/Material";
import { Estimate, EstimateAddOns } from "../types/Estimate";
import { Candidate } from "../types/_candidate.type";
import { EbookUser } from "../types/_ebook-user.type";
import { Slot } from "../types/slot";
import { Question } from "../types/questions";
import { Schedule } from "../types/admit-card";
import { Result, Submission } from "../types/exam-submission";

export interface UserState {
  userProfile: UserModel;
  currentCandidate: Candidate;
  currentEbookUser: EbookUser;
  currentCandidateSubmission: Submission;
  currentExaminerPaper: Submission;
  examinerSubmissions: Submission [];
  results: Result[];
  currentResult: Result;
  ebookUsers: EbookUser[];
  candidates: Candidate[];
  slots: Slot[];
  submissions: Submission[];
  questions: Question[];
  schedule: Schedule;
  adminUsers: AdminUserModel[];
  leads: LeadModel[];
  projects: Project[];
  insuranceCompanies: InsuranceCompany[];
  projectDetails: ProjectDetails;
  materials: Material[];
  estimate: Estimate;
  estimateAddOns: EstimateAddOns[];
}

const initialState: UserState = {
  userProfile: {} as UserModel,
  currentCandidate: {} as Candidate,
  currentEbookUser: {} as EbookUser,
  currentCandidateSubmission: {} as Submission,
  currentExaminerPaper: {} as Submission,
  examinerSubmissions: {} as Submission [],
  results: {} as Result[],
  currentResult: {} as Result,
  ebookUsers: {} as EbookUser[],
  candidates: {} as Candidate[],
  slots: {} as Slot[],
  submissions: {} as Submission[],
  questions: {} as Question[],
  schedule: {} as Schedule,
  adminUsers: {} as AdminUserModel[],
  leads: {} as LeadModel[],
  projects: {} as Project[],
  insuranceCompanies: {} as InsuranceCompany[],
  projectDetails: {} as ProjectDetails,
  materials: {} as Material[],
  estimate: {} as Estimate,
  estimateAddOns: {} as EstimateAddOns[],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin(state, action: PayloadAction<UserModel>) {
      const profileData = action.payload;
      state.userProfile = profileData;
    },
    userLogout(state) {
      state.userProfile = {} as UserModel;
    },
    setCandidates(state, action: PayloadAction<Candidate[]>) {
      state.candidates = action.payload;
    },
    setCurrentCandidate(state, action: PayloadAction<Candidate>) {
      state.currentCandidate = action.payload;
    },
    setCurrentEBookuser(state, action: PayloadAction<EbookUser>) {
      state.currentEbookUser = action.payload;
    },
    setCurrentCandidateSubmission(state, action: PayloadAction<Submission>) {
      state.currentCandidateSubmission = action.payload;
    },
    setCurrentExaminerPaper(state, action:PayloadAction<Submission>) {
      state.currentExaminerPaper = action.payload;
    },
    setExaminerSubmissions(state, action: PayloadAction<Submission[]>) {
      state.examinerSubmissions = action.payload;
    },
    setResults(state, action: PayloadAction<Result[]>) {
      state.results = action.payload;
    },
    setCurrentResult(state, action: PayloadAction<Result>) {
      state.currentResult = action.payload;
    },
    setEbookUsers(state, action: PayloadAction<EbookUser[]>) {
      state.ebookUsers = action.payload;
    },
    setSlots(state, action: PayloadAction<Slot[]>) {
      state.slots = action.payload;
    },
    setSubmissions(state, action: PayloadAction<Submission[]>) {
      state.submissions = action.payload;
    },
    setQuestions(state, action: PayloadAction<Question[]>) {
      state.questions = action.payload;
    },
    setCurrentSchedule(state, action: PayloadAction<Schedule>) {
      state.schedule = action.payload;
    },
    setLeads(state, action: PayloadAction<LeadModel[]>) {
      console.log("ACTION:", action);
      state.leads = action.payload;
    },
    setProjects(state, action: PayloadAction<Project[]>) {
      console.log("ACTION:", action);
      state.projects = action.payload;
    },
    setInsuranceCompanies(state, action: PayloadAction<InsuranceCompany[]>) {
      state.insuranceCompanies = action.payload;
    },
    setProjectDetails(state, action: PayloadAction<ProjectDetails>) {
      state.projectDetails = action.payload;
    },
    setMaterials(state, action: PayloadAction<Material[]>) {
      state.materials = action.payload;
    },
    setEstimate(state, action: PayloadAction<Estimate>) {
      state.estimate = action.payload;
    },
    setEstimateAddOns(state, action: PayloadAction<EstimateAddOns[]>) {
      state.estimateAddOns = action.payload;
    },
    setAdminUsers(state, action: PayloadAction<AdminUserModel[]>) {
      state.adminUsers = action.payload;
    },
  },
});
export const {
  userLogin,
  setCandidates,
  setCurrentCandidate,
  setCurrentEBookuser,
  setCurrentCandidateSubmission,
  setCurrentExaminerPaper,
  setExaminerSubmissions,
  setResults,
  setCurrentResult,
  setEbookUsers,
  setSlots,
  setSubmissions,
  setQuestions,
  setCurrentSchedule,
  setLeads,
  setProjects,
  setInsuranceCompanies,
  setProjectDetails,
  setMaterials,
  setEstimate,
  setEstimateAddOns,
  userLogout,
  setAdminUsers,
} = userSlice.actions;
export default userSlice.reducer;
