import { Result } from "src/redux/types/exam-submission";
import { ManageResultHeader } from "./manage-results-header";
import { getResults, publishResultAPI } from "src/redux/api/_exams.api";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux/hooks";
import { setResults } from "src/redux/reducers";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { LimitSelector } from "src/components/ui/limit-selector";
import { GenericPagination } from "src/components/ui/pagination";
import { Button } from "src/components/buttons/shadcn-button";
import { TrashIcon } from "lucide-react";
import { StateList } from "src/utils/states";
import { PublishResultDialog } from "./PublishResultDialog";
import { toast } from "react-toastify";

interface ResultListViewProps {
  onClickDetail: (submission: Result) => void;
}

const ResultListView = ({ onClickDetail }: ResultListViewProps) => {
  const jobPosts = [
    "RWO",
    "ITWO",
    "HRWO-A",
    "HRWO-B",
    "HRWO-C",
    "HRWO-D",
    "AWO",
    "DWO",
  ];
  type Job = (typeof jobPosts)[number];

  const dispatch = useDispatch();

  const { results } = useAppSelector((state) => state.userReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  const [selectedCandidates, setSelectedCandidates] = useState<number[]>([]);

  const state = searchParams.get("state") || "";
  const post = searchParams.get("post") || ("" as Job);
  const name = searchParams.get("name") || "";
  const admitcard_number = searchParams.get("admitcard_number") || "";
  const pageNumber = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pagesize") || "10", 10);

  const fetchResults = async () => {
    let queryString = "";
    if (state !== "" && state) {
      queryString = `${queryString}&state=${state}`;
    }
    if (name.trim() !== "") {
      queryString = `${queryString}&name=${name}`;
    }
    if (post !== "") {
      queryString = `${queryString}&post=${post}`;
    }
    if (admitcard_number !== "") {
      queryString = `${queryString}&admitcard_number=${admitcard_number}`;
    }
    try {
      const response = await getResults(pageNumber, pageSize, queryString);

      if (response?.message.toLowerCase() === "success") {
        dispatch(setResults(response.data.qualified_candiates));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const publishResult = async () => {
    if (selectedCandidates.length > 0) {
      const response = await publishResultAPI(selectedCandidates);
      if (response.message.toLowerCase() === "success") {
        toast.success("Result Published Successfully!");
        setSelectedCandidates([]);
        fetchResults();
      }
    }
  };

  useEffect(() => {
    fetchResults();
  }, [pageNumber, pageSize, debouncedSearchParams]);

  const goNext = () => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (pageNumber + 1).toString(),
    });
    fetchResults();
  };

  const goPrev = () => {
    if (pageNumber === 1) {
      return;
    }
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (pageNumber - 1).toString(),
    });
  };

  const jumpPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  };

  const onLimitChange = (newLimit) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      pagesize: newLimit,
    });
  };

  const clearAllFilters = () => {
    setSearchParams({});
  };

  const ResultTable = React.memo(() => {
    if (Array.isArray(results)) {
      return (
        <tbody>
          {results.map((result) => {
            return (
              <tr>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      value={result.sl}
                      checked={result.publish ? result.publish : selectedCandidates.includes(result.sl)}
                      onChange={() => {
                        if (selectedCandidates.includes(result.sl)) {
                          setSelectedCandidates(
                            selectedCandidates.filter(
                              (admit) => admit !== result.sl
                            )
                          );
                        } else {
                          setSelectedCandidates([
                            ...selectedCandidates,
                            result.sl,
                          ]);
                        }
                      }}
                    />
                  </label>
                </td>
                <td>{result.candidate_name}</td>
                <td>{result.post}</td>
                <td>{result.state}</td>
                <td>{result.marks}</td>
                <td>{result.result}</td>
                <td>{result.admitcard_number}</td>
                <td>
                  <button
                    className="py-1 px-3 rounded-md border border-black bg-yellow-400 hover:bg-yellow-500/60 font-semibold"
                    onClick={() => onClickDetail(result)}
                  >
                    View Details
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    return <div>Loading...</div>;
  });

  return (
    <>
      <ManageResultHeader />
      <div>
        <div className="p-4 flex justify-between items-center">
          <div className="flex items-center justify-between gap-4 p-5 w-full flex-wrap">
            <label className="input input-bordered flex items-center gap-2">
              <input
                type="text"
                className="grow"
                placeholder="Search by Name"
                value={name}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    name: e.target.value,
                  });
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="w-4 h-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <label className="input input-bordered flex items-center gap-2">
              <input
                type="text"
                className="grow"
                placeholder="Search by Admit Card"
                value={admitcard_number}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    admitcard_number: e.target.value,
                  });
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="w-4 h-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <div className="flex flex-grow gap-3 items-end justify-end">
              <div className="mt-2">
                <select
                  id="state"
                  name="state"
                  autoComplete="state-name"
                  className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 h-9"
                  value={state}
                  onChange={(e) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      state: e.target.value,
                      page: "1",
                    });
                  }}
                >
                  <option selected value="">
                    Select State
                  </option>
                  {StateList.map((item) => (
                    <option key={item.state} value={item.state.toLowerCase()}>
                      {item.state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-between">
                <select
                  value={post}
                  onChange={(e) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      post: e.target.value,
                      page: "1",
                    });
                  }}
                  className="border p-1 rounded-md h-9"
                >
                  <option value="">All Posts</option>
                  {jobPosts.map((post) => (
                    <option value={post.toLowerCase()}>{post}</option>
                  ))}
                </select>
              </div>
              <Button onClick={clearAllFilters}>
                <TrashIcon className="size-5" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>
                <label>
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={() => {
                      if (selectedCandidates.length === results.length) {
                        setSelectedCandidates([]);
                      } else {
                        setSelectedCandidates(
                          results.map((result) => result.sl)
                        );
                      }
                    }}
                  />
                </label>
              </th>
              <th>Name</th>
              <th>Post</th>
              <th>State</th>
              <th>Marks</th>
              <th>Result</th>
              <th>Admit Card Number</th>
            </tr>
          </thead>
          <ResultTable />
        </table>

        <div className="flex items-center justify-between py-5 px-2">
          <LimitSelector
            pageSize={pageSize}
            onValueChange={(newLimit) => onLimitChange(newLimit)}
          />
          <GenericPagination
            currentPage={pageNumber}
            onNext={goNext}
            onPrev={goPrev}
            jumpPage={jumpPage}
          />
          <div>
            <PublishResultDialog onPublish={publishResult}>
              <button className="w-[10rem] px-2 py-2 bg-red-400 rounded-lg text-white border border-black shadow-md hover:bg-red-300 hover:text-black font-semibold">
                PUBLISH RESULT
              </button>
            </PublishResultDialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultListView;
