import { ManageResultDetailsHeader } from "./ManageResultDetailsHeader";
import { useEffect, useState } from "react";
import { ExamSubmission } from "src/redux/types/single-submission";
import { editQualifiedCandidate, getSubmissionByAdmit } from "src/redux/api/_exams.api";
import { useAppSelector } from "src/redux/hooks";
import { UPLOADED_ANSWER_URL } from "src/redux/api/appConst";
import { Form, FormControl, FormField, FormItem } from "src/components/form/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/input/Select";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface ManageResultDetailViewProps {
  defaultView: () => void;
}

interface FormProps {
  result: string;
}

const ManageResultDetailView = ({
  defaultView,
}: ManageResultDetailViewProps) => {
  const [currentPaper, setCurrentPaper] = useState<ExamSubmission[]>();

  const { currentResult } = useAppSelector((state) => state.userReducer);

  const optionalQuestionSubmissions: ExamSubmission[] = [];
  const shortQuestionSubmissions: ExamSubmission[] = [];
  const longQuestionSubmissions: ExamSubmission[] = [];

  const fetchSubmissionByAdmit = async () => {
    try {
      const response = await getSubmissionByAdmit(
        currentResult.admitcard_number
      );

      if (response?.message.toLowerCase() === "success") {
        setCurrentPaper(response.data.exam_submissions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const questionTypeSeparator = (paper: ExamSubmission[]) => {
    paper.forEach((currentPaper) => {
      const questionType = currentPaper.question.type.toLowerCase();

      switch (questionType) {
        case "optional":
          optionalQuestionSubmissions.push(currentPaper);
          break;
        case "short":
          shortQuestionSubmissions.push(currentPaper);
          break;
        case "desc":
          longQuestionSubmissions.push(currentPaper);
          break;
        default:
          break;
      }
    });
  };

  if (currentPaper) {
    questionTypeSeparator(currentPaper!);
  }

  useEffect(() => {
    fetchSubmissionByAdmit();
  }, []);

    const form = useForm<FormProps>();

    const handleEditResult = async (values: FormProps) => {
      try {
        const response = await editQualifiedCandidate(values.result, currentResult.sl);

        if(response.message.toLowerCase() === "success") {
          window.location.reload();
          toast.success("Result Saved Successfully!");
        }
      } catch (error) {
        toast.error("Failed to save Result!");
      }
    };

  const optionalMarksTable = () => {
    if (Array.isArray(optionalQuestionSubmissions)) {
      return optionalQuestionSubmissions.map((paper, index) => (
        <tr key={index}>
          <td>
            {paper.exam_submission.questionNumber} .{paper.question.question}
          </td>
          <td>{paper.exam_submission.answer}</td>
          <td className="text-center">
            {paper.exam_submission.marks_obtained}
          </td>
        </tr>
      ));
    }
    return <div>Loading...</div>;
  };

  const shortMarksTable = () => {
    if (Array.isArray(shortQuestionSubmissions)) {
      return shortQuestionSubmissions.map((paper, index) => (
        <tr key={index}>
          <td>
            {paper.exam_submission.questionNumber}. {paper.question.question}
          </td>
          <td className="w-[40rem]">{paper.exam_submission.answer}</td>
          <td className="text-center">
            {paper.exam_submission.marks_obtained}
          </td>
        </tr>
      ));
    }
    return <div>Loading...</div>;
  };

  const DisplayLongAnswers = () => {
    if (Array.isArray(longQuestionSubmissions)) {
      return (
        <div>
          {longQuestionSubmissions.map((paper, index) => {
            return (
              <div className="border-b border-slate-300 p-2 my-6 " key={index}>
                <h1 className="text-lg">
                  <span>{index + 1}</span>.{" "}
                  <span>{paper.question.question}</span>
                </h1>
                {!!paper.exam_submission.answerUrl ? (
                  <img
                    src={`${UPLOADED_ANSWER_URL}/${paper.exam_submission.answerUrl}`}
                    alt="uploaded answer"
                    className="aspect-[3/4] m-auto max-w-[1080px] my-4"
                  />
                ) : !!paper.exam_submission.answer ? (
                  <h1>{paper.exam_submission.answer}</h1>
                ) : (
                  <h1 className="text-xl font-bold text-center">
                    Not Attempted.
                  </h1>
                )}

                <div className="text-end text-xl">
                  {paper.exam_submission.marks_obtained && (
                    <h1 className="text-xl font-bold text-end pr-4">
                      {" "}
                      Obtained Marks: {paper.exam_submission.marks_obtained}
                    </h1>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return <div>Loading...</div>;
  };

  return (
    <>
      <ManageResultDetailsHeader defaultView={defaultView} />
      <div>
        <div className="flex gap-4 mb-8 pl-6">
          <div className="font-semibold text-xl">
            <h1>Candidate Name</h1>
            <h1>Post Name</h1>
            <h1>State</h1>
            <h1>Admit Card Number</h1>
          </div>
          <div className="font-semibold text-xl">
            <h1 className="uppercase">{currentResult.candidate_name}</h1>
            <h1>{currentResult.post}</h1>
            <h1>{currentResult.state}</h1>
            <h1>{currentResult.admitcard_number}</h1>
          </div>
          <div className="ml-20 text-xl font-bold flex gap-24">
            <div className="flex flex-col justify-center gap-4">
              <h1>
              Qualifying Marks: <span>{currentResult.qualified_marks}</span>
            </h1>
            <h1>
              Total Marks Obtained: <span>{currentResult.marks}</span>
            </h1>
            </div>
            <div className="flex items-center">
              <h1 className="mr-4">
                Result:</h1>
                {
                  currentResult.result ? (<span
                  className={`${
                    currentResult.result?.toLowerCase() === "qualified"
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {currentResult.result}
                </span>) : (
                  <div>
                    <Form {...form}>
                    <form
                      className="flex gap-8"
                      onSubmit={form.handleSubmit(handleEditResult)}
                    >
                      <div>
                        <FormField
                          name="result"
                          control={form.control}
                          render={({ field }) => (
                            <FormItem>
                              <Select
                                onValueChange={field.onChange}
                                value={field.value}
                              >
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select Result" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  <SelectItem value="QUALIFIED">
                                    QUALIFIED
                                  </SelectItem>
                                  <SelectItem value="NOT QUALIFIED">
                                    NOT QUALIFIED
                                  </SelectItem>
                                </SelectContent>
                              </Select>
                            </FormItem>
                          )}
                        />
                      </div>
                      <button
                        type="submit"
                        className="px-3 py-1 text-lg rounded-md bg-green-200 hover:bg-green-500 hover:border hover:border-green-500 border border-black"
                      >
                        SAVE RESULT
                      </button>
                    </form>
                  </Form>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">Optional Answers Marks</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Marks Obtained</th>
              </tr>
            </thead>
            <tbody>{optionalMarksTable()}</tbody>
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">Short Answers Marks</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
                <th>Marks Obtained</th>
              </tr>
            </thead>
            <tbody>{shortMarksTable()}</tbody>
          </table>
        </div>
        <div className="my-8 border-t border-slate-300">
          <h1 className="text-xl font-semibold my-6">
            Descriptive Answers Marks
          </h1>
          <DisplayLongAnswers />
        </div>
      </div>
    </>
  );
};

export default ManageResultDetailView;
