import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/dialogue/radix-dialog";
import { AddQualifyingMarksForm } from "./add-qualifyingmarks-form";

interface QualifyingMarksDialogProps {
  children: React.ReactNode;
}
export const QualifyingMarksDialog = ({ children }: QualifyingMarksDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>Add Qualifying Marks</DialogTitle>
        </DialogHeader>
        <AddQualifyingMarksForm />
      </DialogContent>
    </Dialog>
  );
};
