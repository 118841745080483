/* eslint-disable jsx-a11y/alt-text */
import { Toolbar, Typography, Button } from "@mui/material";
import {
  LeftIcon,
  NavAppBar,
  NavLink,
  NavLinkNormal,
  RightNav,
} from "./navbar.styled";

import NotificationIcon from "../../images/notification.svg";
import AvatarIcon from "../../images/avatar.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { AppCSS, } from "..";
import { useNavigate } from "react-router-dom";
import { RowDiv } from "../misc/misc.styled";
import DownIcon from "@mui/icons-material/ArrowDropDown";
import { useAppSelector } from "../../redux/hooks";


interface NavItemProps {
  title?: string;
  linkTo?: string;
  selected?: boolean;
  onClick?: Function;
  id?: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  title,
  linkTo,
  selected,
}) => {
  return (
    <NavLink
      style={{
        border: selected ? "1px solid #FF5733" : "none",
      }}
      to={linkTo ? linkTo : "#"}
    >
      {title && title}
    </NavLink>
  );
};

export const NavItemNotification: React.FC<NavItemProps> = ({
  linkTo,
  title,
}) => {
  return (
    <NavLinkNormal
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
      to={linkTo ? linkTo : "#"}
    >
      <img src={NotificationIcon} width={28} height={28} />
      {title && (
        <label
          style={{
            color: AppCSS.RED,
            height: "5px",
            width: "5px",
            top: "10px",
            position: "absolute",
            fontSize: "12px",
          }}
        >
          ●
        </label>
      )}
    </NavLinkNormal>
  );
};

export const NavItemProfile: React.FC<NavItemProps> = ({
  linkTo,
  title,
  onClick,
  id,
}) => {
  return (
    <Button
      id={id}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      variant="text"
      onClick={() => (onClick ? onClick() : {})}
    >
      <img src={AvatarIcon} width={38} height={38} />
      {title && (
        <label
          style={{
            color: "#2E2C34",
            fontSize: "13px",
            marginLeft: "10px",
          }}
        >
          {title}
        </label>
      )}
    </Button>
  );
};

export type CurrentPage =
  | "login"
  | "signup"
  | "onboarding"
  | "dashboard"
  | "profile"
  | "admin";

interface ProfileProps {
  isAdmin: boolean;
  isProctor: boolean;
  isFrontDesk: boolean;
  isExaminer: boolean;
}

export const ProfileMenu: React.FC<ProfileProps> = ({isAdmin, isProctor, isFrontDesk, isExaminer}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const allowedMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {isAdmin && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/manage-candidates");
              }}
            >
              Manage Candidates
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/ebook-users");
              }}
            >
              Manage E-book users
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/exams");
              }}
            >
              Exams
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/send-email");
              }}
            >
              Send Notification
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/transactions");
              }}
            >
              Transaction
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/admin-controls");
              }}
            >
              Admin Controls
            </MenuItem>
          </>
        )}

        {isProctor && (
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/manage-exam");
            }}
          >
            Manage Exams
          </MenuItem>
        )}

        {isFrontDesk && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/front-desk");
              }}
            >
              Front Desk
            </MenuItem>
          </>
        )}
        
        { isExaminer && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/examiner-dashboard");
              }}
            >
              Examiner Dashboard
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/logout");
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "none",
        }}
      >
        <RowDiv
          style={{
            alignItems: "center",
          }}
        >
          <label
            style={{
              color: "#2E2C34",
              fontSize: "13px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            {isAdmin
              ? "Admin"
              : isProctor
              ? "Proctor"
              : isFrontDesk
              ? "Front Desk"
              : isExaminer
              ? "Examiner"
              : ""}
          </label>
          <DownIcon color="disabled" />
        </RowDiv>
      </Button>
      {allowedMenu()}
    </div>
  );
};

export const NavBar = ({isAdmin, isProctor, isFrontDesk, isExaminer}) => {
  const navigate = useNavigate();
  const profile = useAppSelector((state) => state.userReducer).userProfile;

  const dashboardMenu = () => {
    if (profile.token) {
      return (
        <RightNav
          style={{
            display: "flex",
            alignItems: "center",
            height: "6:D0px",
          }}
        >
          <ProfileMenu isAdmin={isAdmin} isProctor={isProctor} isFrontDesk={isFrontDesk} isExaminer={isExaminer}/>
        </RightNav>
      );
    }
  };

  const availableOptions = () => {
    if (profile.token) {
      return dashboardMenu();
    } else {
      return (
        <RightNav>
          <NavItem title="Sign in" linkTo="/" />
        </RightNav>
      );
    }
  };

  return (
    <NavAppBar position="sticky" elevation={0}>
      <Toolbar>
        <LeftIcon
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            mr: 2,
            fontWeight: "bold",
            "&:hover": {
              background: "none",
            },
          }}
          onClick={() => navigate("/dashboard")}
        >
          { isAdmin ? "NHWM Admin" : isProctor ? "NHWM Proctor" : isFrontDesk ? "NHWM Front Desk" : isExaminer ? "NHWM Examiner" : ""}
        </LeftIcon>
        <Typography
          color="#414141"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        {availableOptions()}
      </Toolbar>
    </NavAppBar>
  );
};
