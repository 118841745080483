
import jwtDecode from "jwt-decode";
import { TrashIcon } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "src/components/buttons/shadcn-button";
import { LimitSelector } from "src/components/ui/limit-selector";
import { GenericPagination } from "src/components/ui/pagination";
import { getExamSubmission } from "src/redux/api/_exams.api";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setExaminerSubmissions } from "src/redux/reducers";
import { Submission } from "src/redux/types/exam-submission";
import { StateList } from "src/utils/states";
import { useDebounce } from "use-debounce";


interface ExaminerPaperListViewProps {
  onClickCheck : (submission: Submission) => void;
}

interface DecodedToken {
  "cognito:groups"?: string[];
}

const ExaminerPaperListView = ({onClickCheck}: ExaminerPaperListViewProps) => {

  const dispatch = useAppDispatch();
  const { examinerSubmissions } = useAppSelector((state) => state.userReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const [debouncedSearchParams] = useDebounce(searchParams, 1000);

  const state = searchParams.get("state") || "";
  const name = searchParams.get("name") || "";
  const admitCardNumber = searchParams.get("admitCardNumber") || "";
  const checked = searchParams.get("checked") || "";
  const pageNumber = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pagesize") || "10", 10);

  const [filteredSubmissions, setFilteredSubmissions] = useState<Submission[]>([]);

  const profile = useAppSelector((state) => state.userReducer).userProfile;

  const decoded = profile.token ? jwtDecode<DecodedToken>(profile.token) : "";
  const userGroup = decoded ? decoded["cognito:groups"] : "";

  const filterExaminerSubmissions = () => {

    const examinerPosts = {
      EXAMINER_RWO: "rwo",
      EXAMINER_HRWO: "hrwo",
      EXAMINER_ITWO: "itwo",
      EXAMINER_DWO: "dwo",
      EXAMINER_AWO: "awo",
    }

    const matchedPost = Object.keys(examinerPosts).find((examiner) => userGroup?.includes(examiner));

    if(matchedPost) {
      if(Array.isArray(examinerSubmissions)) {
        const filteredExaminerSubmissions = examinerSubmissions.filter((paper) => paper.postName.toLowerCase() === examinerPosts[matchedPost]);
        setFilteredSubmissions(filteredExaminerSubmissions);
      }
    }
  };

  useEffect(()=> {
    filterExaminerSubmissions();
  },[examinerSubmissions])

  const fetchExamSubmissions = async () => {
    let queryString = "";
    if (state !== "Select one" && state) {
      queryString = `${queryString}&state=${state}`;
    }
    if (name.trim() !== "") {
      queryString = `${queryString}&name=${name}`;
    }
    if (admitCardNumber !== "") {
      queryString = `${queryString}&admitCardNumber=${admitCardNumber}`;
    }
    if (checked !== "") {
      queryString = `${queryString}&checked=${checked}`;
    }

    try {
      const response = await getExamSubmission(
        pageNumber,
        pageSize,
        queryString
      );
      if (response?.message.toLowerCase() === "success") {
        dispatch(setExaminerSubmissions(response.data.exam_submissions));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchExamSubmissions();
  }, [pageNumber, pageSize, debouncedSearchParams]);

  const goNext = () => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (pageNumber + 1).toString(),
    });
    fetchExamSubmissions();
  };

  const goPrev = () => {
    if (pageNumber === 1) {
      return;
    }
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: (pageNumber - 1).toString(),
    });
  };

  const jumpPage = (page: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: page.toString(),
    });
  };

  const onLimitChange = (newLimit) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      pagesize: newLimit,
    });
  };

  const clearAllFilters = () => {
    setSearchParams({});
  };

  const SubmissionTable = React.memo(() => {
    if (Array.isArray(filteredSubmissions)) {
      return (
        <tbody>
          {filteredSubmissions.map((submission) => {
            return (
              <tr>
                <td>{submission.admitCardNumber}</td>
                <td>{submission.candidateName}</td>
                <td>{submission.postName}</td>
                <td>{submission.state}</td>
                <td>{moment(submission.startTime).format("DD-MM-YYYY")}</td>
                <td>{moment(submission.startTime).format("h:mm a")}</td>
                <td>
                  {submission.checked ? (
                    <span className="text-green-500 font-semibold text-lg">
                      Checked
                    </span>
                  ) : (
                    <button
                      className="py-1 px-3 rounded-md border border-black bg-yellow-400 hover:bg-yellow-500/60 font-semibold"
                      onClick={() => onClickCheck(submission)}
                    >
                      Check Paper
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    }
    return null;
  });

  return (
    <>
      <div className="my-3 px-4">
        <h1 className="font-semibold text-3xl">Check Papers</h1>
      </div>
      <div>
        <div className="p-4 flex justify-between items-center">
          <div className="flex items-center justify-between gap-4 p-5 w-full flex-wrap">
            <label className="input input-bordered flex items-center gap-2">
              <input
                type="text"
                className="grow"
                placeholder="Search by Name"
                value={name}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    name: e.target.value,
                  });
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="w-4 h-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <label className="input input-bordered flex items-center gap-2">
              <input
                type="text"
                className="grow"
                placeholder="Search by Admit Card"
                value={admitCardNumber}
                onChange={(e) => {
                  setSearchParams({
                    ...Object.fromEntries(searchParams),
                    admitCardNumber: e.target.value,
                  });
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="w-4 h-4 opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </label>
            <div className="flex flex-grow gap-3 items-end justify-end">
              <div className="mt-2">
                <select
                  id="checked"
                  name="checked"
                  autoComplete="check-status"
                  className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 h-9"
                  value={checked}
                  onChange={(e) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      checked: e.target.value,
                      page: "1",
                    });
                  }}
                >
                  <option selected value="">
                    Select Status
                  </option>
                  <option value="true">CHECKED</option>
                  <option value="false">NOT CHECKED</option>
                </select>
              </div>
              <div className="mt-2">
                <select
                  id="state"
                  name="state"
                  autoComplete="state-name"
                  className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 h-9"
                  value={state}
                  onChange={(e) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      state: e.target.value,
                      page: "1",
                    });
                  }}
                >
                  <option selected value="">
                    Select State
                  </option>
                  {StateList.map((item) => (
                    <option key={item.state} value={item.state}>
                      {item.state}
                    </option>
                  ))}
                </select>
              </div>
              <Button onClick={clearAllFilters}>
                <TrashIcon className="size-5" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Admit Card Number</th>
              <th>Name</th>
              <th>Post</th>
              <th>State</th>
              <th>Date</th>
              <th>Start Time</th>
              <th>Check Paper</th>
            </tr>
          </thead>
          <SubmissionTable />
        </table>
        <div className="flex items-center justify-between py-5 px-2">
          <LimitSelector
            pageSize={pageSize}
            onValueChange={(newLimit) => onLimitChange(newLimit)}
          />
          <GenericPagination
            currentPage={pageNumber}
            onNext={goNext}
            onPrev={goPrev}
            jumpPage={jumpPage}
          />
        </div>
      </div>
    </>
  );
}

export default ExaminerPaperListView