import { Schedule } from "src/redux/types/admit-card";
import { DeleteAdmitDialog } from "./delete-dialog";
import moment from "moment";
import { ViewAdmitDialog } from "./ViewAdmitDialog";

interface TableRowProps {
  schedule: Schedule;
  viewAdmit: (schedule: Schedule) => void;
  onClick: (id: number) => void;
}
export const ManageAdmitTableRow = ({
  schedule,
  viewAdmit,
  onClick,
}: TableRowProps) => {
  const { slot, ...keys } = schedule;

  const isExamMissed = (startTime: string, status: string): boolean => {

    const examTime = new Date(startTime);
    const now = new Date();

    // Calculate the time 10 minutes before the exam start time
    const latestEntryTime = new Date(examTime);
    latestEntryTime.setMinutes(examTime.getMinutes() - 10);

    // Return true if the current time is after 10 minutes before the exam start time
    if (now > latestEntryTime) {
      if (status.toLowerCase() !== "appearing") {
        if (status.toLowerCase() !== "appeared") {
          if (status.toLowerCase() !== "terminated") {
            return true;
          }
        }
      }
    }

    return false;
  };

  const isMissed = isExamMissed(schedule.slot.startTime, schedule.status);

  return (
    <tr>
      <td className="p-1 flex flex-col"><span className="font-semibold">{schedule.candidateName}</span><span>{schedule.candidateEmail}</span></td>
      <td className="p-1">{schedule.phoneNumber}</td>
      <td className="p-1">{schedule.whatsAppNumber}</td>
      <td className="p-1">{schedule.admitCardNumber}</td>
      <td className="p-1">{schedule.postName}</td>
      <td className="p-1">{schedule.slot.state}</td>
      <td className="p-1">{moment(schedule.slot.date).format("DD-MM-YY")}</td>
      <td className="p-1">
        {moment(schedule.slot.startTime).format("hh:mm a")}
      </td>
      <td>{schedule.proctors_note}</td>
      <td className={`${isMissed ? "text-red-500" : ""} p-1 font-medium`}>
        {isMissed ? (
          "MISSED"
        ) : schedule.status.toLowerCase() === "appearing" ? (
          <span className="text-amber-500">{schedule.status}</span>
        ) : schedule.status.toLowerCase() === "appeared" ? (
          <span className="text-green-500">{schedule.status}</span>
        ) : schedule.status.toLowerCase() === "terminated" ? (
          <span className="text-red-500">{schedule.status}</span>
        ) : (
          <span className="text-black">{schedule.status}</span>
        )}
      </td>
      <td className="p-1">
        <ViewAdmitDialog schedule={schedule}>
          <button
            className="bg-gray-200 hover:bg-gray-200/60 h-8 px-2 py-1 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm text-black  transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring hover:ring-1 hover:ring-black"
            onClick={() => viewAdmit(schedule)}
          >
            View Admit
          </button>
        </ViewAdmitDialog>
      </td>
      <td className="p-1">
        <DeleteAdmitDialog onClick={() => onClick(schedule.id)} name = {schedule.candidateName}>
          <button className="bg-red-500/80 hover:bg-red-500/60 h-8 px-2 py-1 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm text-white hover:text-black font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
            Delete
          </button>
        </DeleteAdmitDialog>
      </td>
    </tr>
  );
};
